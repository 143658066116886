export enum SuccesBoxesEnum {
  Dictionary = "Успешно създадохте нов речник!",
  Lesson = "Успешно създадохте нов урок!",
}
export enum FailedBoxesEnum {
  DictionaryDuplicated = "Речник с това име вече съществува!",
  LessonDuplicated = "Урок с това име вече съществува",
  WordDuplicated = "Дума с това име вече съществува",
  ExpressionDuplicated = "Израз с това име вече съществува",
}
export enum FailedBoxesType {
  Dictionary = "Dictionary",
  Lesson = "Lesson",
  Word = "Word",
  Expression = "Expression",
}

export enum RedirectEnum {
  Dashboard = "/",
  CreateDictionary = "/create-dictionary",
  CreateLesson = "/create-lesson",
  Lesson = "/lesson",
  SignUp = "/sign-up",
  SignIn = "/sign-in",
  AddWordToLesson = "/add-word-to-lesson",
  AddExpressionToLesson = "/add-expression-to-lesson",
  LearnWords = "/learn-words",
  LearnExpressions = "/learn-expressions",
  NotFound = "*",
  Settings = "/settings",
  LogOut = "/logOut",
}
