import {
  useQuery,
  UseQueryResult,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios from "axios";
import { RedirectEnum } from "types/enums";
import { AllUserData } from "types/interfaces";

const useGetUserData = (): UseQueryResult<AllUserData, Error> => {
  const getUserData = async (): Promise<AllUserData> => {
    const email = localStorage.getItem("email");
    const accessToken = localStorage.getItem("accessToken");

    if (!email) {
      throw new Error("Email is not available in local storage");
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_GET_USER_DATA}?email=${email}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.accessToken) {
        localStorage.setItem("accessToken", response.data.accessToken);
      }

      return response.data;
    } catch (error: any) {
      console.log(error);
      // need to set a screen with message for expired token and need of new log in

      if (error.response && error.response.data.accessToken) {
        localStorage.setItem("accessToken", error.response.data.accessToken);
      }
      throw error;
    }
  };

  const queryOptions: UseQueryOptions<AllUserData> = {
    queryKey: ["userData"],
    queryFn: getUserData,
  };

  return useQuery<AllUserData>(queryOptions);
};

export default useGetUserData;
