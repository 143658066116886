import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "./features/SignIn/SignIn.tsx";
import SignUp from "./features/SignUp/SignUp.tsx";
import Dashboard from "./features/Dashboard/Dashboard.tsx";
import CreateDictionary from "./features/CreateDictionary/CreateDictionary.tsx";
import CreateLesson from "./features/CreateLesson/CreateLesson.tsx";
import AddWordToLesson from "./features/AddWordToLesson/AddWordToLesson.tsx";
import AddExpressionToLesson from "./features/AddExpressionToLesson/AddExpressionToLesson.tsx";
import LearnWords from "./features/LearnWords/LearnWords.tsx";
import LearnExpressions from "./features/LearnExpressions/LearnExpressions.tsx";
import Settings from "./features/Settings/Settings.tsx";
import NotFound from "./components/NotFound/NotFound.tsx";
import { RedirectEnum } from "./types/enums.ts";
import useGetAllDictionaries from "./hooks/useGetAllDictionaries";
import useGetAllLessons from "./hooks/useGetAllLessons";
import useGetUserData from "./hooks/useGetUserData";

import "./App.css";

function RoutesWrapper() {
  return (
    <Routes>
      <Route path={RedirectEnum.Dashboard} element={<Dashboard />} />
      <Route path={RedirectEnum.SignIn} element={<SignIn />} />
      <Route path={RedirectEnum.SignUp} element={<SignUp />} />
      <Route
        path={RedirectEnum.CreateDictionary}
        element={<CreateDictionary />}
      />
      <Route path={RedirectEnum.CreateLesson} element={<CreateLesson />} />
      <Route
        path={RedirectEnum.AddWordToLesson}
        element={<AddWordToLesson />}
      />
      <Route
        path={RedirectEnum.AddExpressionToLesson}
        element={<AddExpressionToLesson />}
      />
      <Route path={RedirectEnum.LearnWords} element={<LearnWords />} />
      <Route
        path={RedirectEnum.LearnExpressions}
        element={<LearnExpressions />}
      />
      <Route path={RedirectEnum.Settings} element={<Settings />} />
      <Route path={RedirectEnum.NotFound} element={<NotFound />} />
    </Routes>
  );
}

function App() {
  const {
    data: dataDictionaries,
    refetch: refetchDictionaries,
    isError: isErrorDictionaries,
    isLoading: isLoadingDictionaries,
  } = useGetAllDictionaries();

  const {
    data: dataLessons,
    refetch: refetchLessons,
    isError: isErrorLessons,
    isLoading: isLoadingLessons,
  } = useGetAllLessons();

  const {
    data: dataUserData,
    refetch: refetchUserData,
    isError: isErrorUserData,
    isLoading: isLoadingUserData,
  } = useGetUserData();

  const accessToken = localStorage.getItem("accessToken");
  useEffect(() => {
    if (!dataDictionaries) refetchDictionaries();
    if (!dataLessons) refetchLessons();
    if (!dataUserData) refetchUserData();
    console.log(dataDictionaries);
    console.log(dataLessons);
    console.log(dataUserData);
  }, [dataDictionaries, dataLessons, accessToken]);

  return <RoutesWrapper />;
}

export default App;
